<template>
    <form autocomplete="nothanks" class="gb-form" @submit.prevent="$emit('submit', $event)">
        <slot :isChanged="isChanged" />
    </form>
</template>

<script>
export default {
    name: 'FormState',
    props: {
        initialState: {
            type: Object,
            required: true,
            default: {}
        },
        currentState: {
            type: Object,
            required: true,
            default: {}
        }
    },
    computed: {
        isChanged() {
            return JSON.stringify(this.initialState) !== JSON.stringify(this.currentState)
        }
    }
}
</script>
